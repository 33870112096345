  function isoToDate(isoDateTimeFormat) {
    let date = new Date(Date.parse(isoDateTimeFormat));
    return ('00' + date.getDate()).slice(-2) + '.' + ('00' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
  }
  
  function isoToDateTime(isoDateTimeFormat) {
    let date = new Date(Date.parse(isoDateTimeFormat));
    return ('00' + date.getDate()).slice(-2) + '.' + ('00' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ('00' + date.getHours()).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2) + ':' + ('00' + date.getSeconds()).slice(-2);
  }
  
  function isoToTime(isoDateTimeFormat) {
    let date = new Date(Date.parse(isoDateTimeFormat));
    return ('00' + date.getHours()).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2) + ':' + ('00' + date.getSeconds()).slice(-2);
  }
  
  function isoToMysqlDate(isoDateTimeFormat) {
    let date = new Date(Date.parse(isoDateTimeFormat));
    return date.getFullYear() + '-' + ('00' + (date.getMonth() + 1)).slice(-2) + '-' + ('00' + date.getDate()).slice(-2);
  }
  
  function isoToMysqlDateTime(isoDateTimeFormat) {
    let date = new Date(Date.parse(isoDateTimeFormat));
    return date.getFullYear() + '-' + ('00' + (date.getMonth() + 1)).slice(-2) + '-' + ('00' + date.getDate()).slice(-2) + ' ' + ('00' + date.getHours()).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2) + ':' + ('00' + date.getSeconds()).slice(-2);
  }
  
  function isoToMysqlTime(isoDateTimeFormat) {
    let date = new Date(Date.parse(isoDateTimeFormat));
    return ('00' + date.getHours()).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2) + ':' + ('00' + date.getSeconds()).slice(-2);
  }
  
  function inputDateTimeToIsoDateTime(inputDateTimeFormat) {
    return inputDateTimeFormat ? new Date(Date.parse(inputDateTimeFormat)).toISOString() : null
  }
  
  function isoDateTimeToInputDateTime(isoDateTimeFormat) {
    return isoToMysqlDateTime(isoDateTimeFormat).replace(" ", "T");
  }

  function getCreditStatusName(creditRequestStatus) {
    if(creditRequestStatus === 1){
      return 'Onay Bekliyor'
    } else if(creditRequestStatus === 2){
      return 'Onaylandı'
    } else if(creditRequestStatus === 3){
      return 'Reddedildi'
    } else if(creditRequestStatus === 4){
      return 'İptal Edildi'
    } else if(creditRequestStatus === 5){
      return 'İşleme Alındı'
    } else if(creditRequestStatus === 6){
      return 'Evrak Bekleniyor'
    } else if(creditRequestStatus === 7){
      return 'Eksik Evrak Bekleniyor'
    } else if(creditRequestStatus === 8){
      return 'Kefil Bekleniyor'
    } else if(creditRequestStatus === 9){
      return 'Onay Sürecinde'
    }  
    return '-'
  }

  function priceFormat(price) {
    return Intl.NumberFormat('tr-TR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(price);
  }

  function slugify(string) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧıîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');
    
    return string.toString().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(p, c => b.charAt(a.indexOf(c)))
    .replace(/&/g, '-and-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
  }

  module.exports = {isoToDate, isoToDateTime, isoToTime, isoToMysqlDate, isoToMysqlDateTime, isoToMysqlTime, inputDateTimeToIsoDateTime, isoDateTimeToInputDateTime, getCreditStatusName, priceFormat, slugify};