import React from 'react';
import {Link, useNavigate} from 'react-router-dom';

import Images from '../utils/Images';
import config from '../inc/config';

class AnonymousFrameModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  render() {
    return (
      <>
        <nav className="navbar navabr-dark navbar-expand-lg bg-green text-black p-2 p-lg-2">
          <div className="container py-0 py-md-2">
            <Link className="header-logo" title="Logo">
              <img alt="logo" className="m-0" src={Images.Wlogo}></img>
            </Link>
            <button className="navbar-toggler bg-green" data-target="#navbar-satisgaranti" data-toggle="collapse">
              <span className="fa fa-bars bg-green text-white"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar-satisgaranti">
              <ul className="nav navbar-nav navbar-right ml-auto">
                <a href={"https://uye." + config.host + "/login?ref=kredi"}>
                  <li className="nav-item mr-md-2 mt-1 mt-lg-0">
                    <button className="btn btn-white font-bold border-green text-green w-100">Giriş Yap</button>
                  </li>
                </a>
                <a href={"https://uye." + config.host + "/register?ref=kredi"}>
                  <li className="nav-item mr-md-2 mt-1 mt-lg-0">
                    <button className="btn btn-white font-bold border-green text-green w-100">Kayıt Ol</button>
                  </li>
                </a>
              </ul>
            </div>
          </div>
        </nav>
        <div className="mainpageImage">
          <div className="text-center pt-4">
            <h1 className="text-green pt-4 font-weight-normal">Kredi işlemlerinize devam edebilmek için lütfen giriş yapınız.</h1>
          </div>
        </div>
      </>
    )
  }
}

export default function AnonymousFrame() {
  return (
    <AnonymousFrameModule navigate={useNavigate()}></AnonymousFrameModule>
  )
}