  import React from 'react';
  import {Link, useNavigate, useSearchParams} from 'react-router-dom';

  import swal from 'sweetalert';
  import Images from '../utils/Images';
  import clientData from '../inc/clientData'
  import config from '../inc/config';

  import {CustomWidgetPrepare} from '../components/CustomWidget';
  import {isoToDateTime, priceFormat} from '../inc/helper';

  class CreditProviderModule extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isLoading: true,

        currentStep: 0,
        creditRequestId: 0,
        remainingStep: '',
        selectedProviderId: 0,

        creditProviders: [],
        forms: [],
        groups: [],

        advertInfo: {},

        datas: {
          workPlace_city: "",
          workPlace_town: "",
          workPlace_district: "",
          workPlace_quarter: "",
          homePlace_city: "",
          homePlace_town: "",
          homePlace_district: "",
          homePlace_quarter: ""
        }
      }
    }

    componentDidMount(){
      this.getAdvertDetail();
      window.scrollTo(0, 0);
    }

    getAdvertDetail() {
      const requestOptions = {
        method: 'GET',
        headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
      };
  
      let errorMessages = [];
      let remaining = 2;
      let complete = () => {
        remaining--;
        if (remaining === 0) {
          this.setState({isLoading: false});
          if (errorMessages.length > 0) {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: errorMessages[0],
              buttons: 'Tamam'
            });
          }
        }
      }
  
      fetch(config.restApi + 'advert/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200:
            this.setState({advertInfo: responseData.advertInfo});
          break;
          default:
            errorMessages.push(responseData.message);
          break;
        }
        complete();
      }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  
      fetch(config.restApi + 'creditProviders/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200:
            this.setState({creditProviders: responseData.creditProviders});
          break;
          case 205:
            swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: responseData.message, buttons: 'Tamam'}).then(() => this.props.navigate('/newCreditApply'));
          break;
          default:
            errorMessages.push(responseData.message);
          break;
        }
        complete();
      }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
    }

    formStep(step) {
      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({
          advertId: parseInt(this.props.searchParams.get("id")),
          step: step,
          datas: this.state.datas
        })
      };

      fetch(config.restApi + 'creditRequest/tr/' + this.state.selectedProviderId, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200:
            this.setState({isLoading: false, currentStep: step, creditRequestId: responseData.creditRequestId, remainingStep: responseData.remainingStep, groups: responseData.groups, forms: responseData.forms, datas: {...this.state.datas, ...responseData.datas}});
          break;
          case 203:
            swal({dangerMode: true, icon: 'info', title: 'Eksik Bilgi', text: responseData.message, buttons: 'Tamam'});
          break;
          case 205:
            swal({dangerMode: true, icon: 'info', title: 'Eksik Bilgi', text: responseData.message, buttons: 'Tamam'}).then(() => this.setState({isLoading: false}));
            break;
          case 404:
            swal({dangerMode: true, icon: 'info', title: 'Eksik Bilgi', text: responseData.message, buttons: 'Tamam'}).then(() => this.setState({isLoading: false, selectedProviderId: 0}));
          break;
          default:
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}).then(() => this.setState({isLoading: false})));
    }

    advertStatus(id) {
      if(id === 1) {
        return 'Onay Bekliyor'
      } else if(id === 2) {
        return 'Yayında'
      } else if(id === 3) {
        return 'Reddedildi'
      } 
      return 'Pasif'
    }

    render() {
      return this.state.isLoading ?
        <div className="ibox-content sk-loading position-static">
          <div className="sk-spinner sk-spinner-fading-circle">
            <div className="sk-circle1 sk-circle"></div>
            <div className="sk-circle2 sk-circle"></div>
            <div className="sk-circle3 sk-circle"></div>
            <div className="sk-circle4 sk-circle"></div>
            <div className="sk-circle5 sk-circle"></div>
            <div className="sk-circle6 sk-circle"></div>
            <div className="sk-circle7 sk-circle"></div>
            <div className="sk-circle8 sk-circle"></div>
            <div className="sk-circle9 sk-circle"></div>
            <div className="sk-circle10 sk-circle"></div>
            <div className="sk-circle11 sk-circle"></div>
            <div className="sk-circle12 sk-circle"></div>
          </div>
        </div>
        : this.state.selectedProviderId === 0 ?
        <div className="col-lg-12">
          <div className="row">
            <div className="col">
              <div className="ibox">
                <div className="ibox-title text-black d-flex justify-content-between pr-4">
                  <h4>Kredi Sağlayıcıları</h4>
                  <button className="btn border bg-darkblue text-white" onClick={() => this.props.navigate(-1)} title="Geri"><i className="fa fa-arrow-left"></i> Geri Dön</button>
                </div>
                <div className="ibox-content min-height">
                  <div className="row mb-4">
                    <div className="col">
                      <div className="table-responsive-lg">
                        <table className="table table-bordered text-center">
                          <thead>
                            <tr className="text-black">
                              <th>İlan No</th>
                              <th>İlan Sahibi</th>
                              <th>İlan Başlığı</th>
                              <th>Fiyat</th>
                              <th>İlan Tarihi</th>
                              <th>Bölge</th>
                              <th>İlan Durumu</th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            <tr>
                              <td className="align-middle">
                                {this.state.advertInfo.photos.length > 0 ? <img alt="İlan Görseli" className="w-md-50" style={{ height: 100, width:200, objectFit:"contain", overflow: "visible"}} src={`https://file.satisgaranti.com/${this.state.advertInfo.photos[0].path}`}/>
                                : <i className="fa fa-camera d-flex justify-content-center align-items-center" style={{height: 100, objectFit:"contain", overflow: "visible", fontSize: 48, color: '#d2d2d2' }}></i>}<br /><strong className="text-darkblue font-bold"> İlan No: #{this.state.advertInfo.id}</strong>
                              </td>
                              <td className="align-middle">{this.state.advertInfo.memberFullName}</td>
                              <td className="align-middle">{this.state.advertInfo.languages.find((languageInfo) => languageInfo.language === 'tr').title}</td>
                              <td className="align-middle">{priceFormat(this.state.advertInfo.price)} {this.state.advertInfo.currencySymbol}</td>
                              <td className="align-middle">{isoToDateTime(this.state.advertInfo.addedDate)}</td>
                              <td className="align-middle">{this.state.advertInfo.cityName + '/' + this.state.advertInfo.townName}</td>
                              <td className="align-middle">{this.advertStatus(this.state.advertInfo.advertStatus)}</td>
                            </tr>
                          }
                          {this.state.advertInfo.length === 0 && <tr><td className="align-middle p-3 text-black" colSpan="7">İlan Bilgisi Bulunamadı !</td></tr>}
                          </tbody>
                        </table>
                      </div>
                      <p className="text-black mt-4">* Yukarıdaki ilana ait kredi başvurunuza devam edebilmek için lütfen aşağıda belirtilen kredi sağlayıcılarından bir tanesini seçiniz ve gerekli alanları doldurunuz.</p>
                    </div>
                  </div>
                  <div className="row">
                    {
                      this.state.creditProviders.map((creditProviderInfo) => 
                      <div className="cols-sm-12 col-md-6 col-lg-4 mb-3" key={creditProviderInfo.id}>
                        <div className="card">
                          <img alt={creditProviderInfo.name} className="card-img-top" src={creditProviderInfo.logoPath + creditProviderInfo.id + '/web.png'} />
                          <div className="card-body d-flex flex-column bg-muted">
                            <h2 className="card-title text-center font-bold text-dark">{creditProviderInfo.name}</h2>
                            <h4 className="card-text text-center text-dark font-normal mb-5">{creditProviderInfo.description}</h4>
                            <div className="d-flex justify-content-center mt-auto"> 
                              <button className="btn btn-green font-bold w-100 p-2" onClick={() => this.setState({isLoading: true, selectedProviderId: creditProviderInfo.id}, () => this.formStep(1))} title="Başvuru Yap">Başvuru Yap</button>
                            </div>
                          </div>
                        </div>
                      </div> 
                      )
                    }
                    {this.state.creditProviders.length === 0 && <div className="d-flex justify-content-center m-auto text-black"><h3>İlana Ait Kredi Sağlayıcısı Bulunamadı !</h3></div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="ibox">
          {
            this.state.remainingStep !== 0 ? 
            <>
              <div className="ibox-title pr-4">
                <div className="d-flex justify-content-start">
                  <h5 className="text-black">Kredi Başvuru Formu</h5>
                </div>
              </div>
              <div className="ibox-content">
                {
                this.state.groups.map(groupInfo =>
                <div key={groupInfo.groupId}>
                  <h3 className="text-center btn-gray text-black p-2">{groupInfo.groupName}</h3>
                  {this.state.forms.filter(formItem => formItem.groupId === groupInfo.groupId).map((formInfo) => <CustomWidgetPrepare key={formInfo.optionId} defaults={this.state.datas} defaultOnChange={(defaults) => this.setState({datas: defaults}, () => console.log(defaults))} fieldInfo={formInfo} />)}
                </div>)
                }
                <div className="d-flex justify-content-end">
                  {this.state.currentStep !== 1 ?
                    <div>
                      <button className="btn border bg-darkblue text-white text-decoration-underline mr-1" onClick={() => this.formStep(this.state.currentStep - 1)}><i className="fa fa-arrow-left"></i> Geri</button>
                      <button className="btn border bg-green text-white text-decoration-underline" onClick={() => this.formStep(this.state.currentStep + 1)}>İleri <i className="fa fa-arrow-right"></i></button>
                    </div>
                    :
                    <div>
                      <button className="btn border bg-darkblue text-white text-decoration-underline mr-1" onClick={() => this.props.navigate(this.state.currentStep - 1)}><i className="fa fa-arrow-left"></i> Geri</button>
                      <button className="btn border bg-green text-white text-decoration-underline" onClick={() => this.formStep(this.state.currentStep + 1)}>İleri <i className="fa fa-arrow-right"></i></button>
                    </div>
                  }
                </div>
              </div>
            </>
            :
            <div className="ibox-content d-flex justify-content-center align-items-center vh-100">
              <div className="text-center">
                <h1 className="text-green font-serif">Başvurunuz Kaydedildi !</h1>
                <h1 className="text-green font-serif mb-5">Başvurunuzun değerlendirilebilmesi için evrak yüklemeniz gereklidir !</h1>
                <Link className="btn btn-green float-center" to={'/creditRequestDetail?id=' + this.state.creditRequestId}><i className="fa fa-file mr-2"></i>Evrak Yükle</Link>
              </div>              
            </div>
          }
          {/* <div className="ibox-title pr-4">
            <div className="d-flex justify-content-between">
              <h5 className="text-black">Kredi Başvuru Formu</h5>
              {this.state.currentStep !== 1 ?
                <div>
                  <button className="btn btn-darkblue text-white mr-2" onClick={() => this.formStep(this.state.currentStep - 1)}><i className="fa fa-arrow-left"></i> Geri</button>
                  <button className="btn btn-darkblue text-white" onClick={() => this.formStep(this.state.currentStep + 1)}>İleri <i className="fa fa-arrow-right"></i></button>
                </div>
                :
                <div>
                  <button className="btn btn-darkblue text-white mr-2" onClick={() => this.props.navigate(this.state.currentStep - 1)}><i className="fa fa-arrow-left"></i> Geri</button>
                  <button className="btn btn-darkblue text-white" onClick={() => this.formStep(this.state.currentStep + 1)}>İleri <i className="fa fa-arrow-right"></i></button>
                </div>
              }
            </div>
          </div>
          <div className="ibox-content">
            {this.state.remainingStep !== 0 ? this.state.groups.map(groupInfo =>
            <div key={groupInfo.groupId}>
              <h3 className="text-center bg-darkblue text-white p-2">{groupInfo.groupName}</h3>
              {this.state.forms.filter(formItem => formItem.groupId === groupInfo.groupId).map((formInfo) => <CustomWidgetPrepare key={formInfo.optionId} defaults={this.state.datas} defaultOnChange={(defaults) => this.setState({datas: defaults}, () => console.log(defaults))} fieldInfo={formInfo} />)}
            </div>)
            :
            <div className="ibox">
              <div className="ibox-content d-flex justify-content-center">
                <h1 className="text-green">Kredi Başvurunuz Başarıyla Tamamlanmıştır</h1>
                <button className="btn btn-green" onClick={() => this.props.navigate('/')}> Anasayfaya Dön</button>
              </div>
            </div>

            }
          </div> */}
        </div>
    }
  }

  export default function CreditProviders(props) {
    return (
      <CreditProviderModule navigate={useNavigate()} searchParams={useSearchParams()[0]}>{props.children}</CreditProviderModule>
    )
  }