import React from 'react';

class WelcomeModule extends React.Component {
  render() {
    return (
      <div className="row d-flex justify-content-center align-items-center welcomeImage">
        <div className="col text-center p-0">
          <h1 className="font-arial text-black text-h2">KREDİ SAYFAMIZA</h1>
          <h1 className="font-arial font-bold text-black text-h1">HOŞ GELDİNİZ</h1>
          <h1 className="font-italic text-black text-h3">Kredi ile ilgili işlemlerinize buradan devam edilecektir.</h1>
        </div>
      </div>
    )
  }
}

export default function Welcome() {
  return (
    <WelcomeModule />
  )
}