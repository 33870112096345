import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import clientData from '../inc/clientData';
import config from '../inc/config';
import {isoToDateTime, getCreditStatusName, isoToDate, isoToMysqlTime} from '../inc/helper';
import Images from '../utils/Images';

class CreditRequestHistoryModule extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isLoading: true,

      memberId: 0,
      comment: '',
      creditRequestHistories: [],
      creditRequestInfo: {}
    }

    this.chatScrollToBottom = React.createRef();
    this.saveCommentOnClick = this.saveCommentOnClick.bind(this);
  }

  componentDidMount() {
    this.getCreditRequestHistories();
    this.getCreditDetail();
    this.scrollToBottom();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.creditRequestHistories.length < this.state.creditRequestHistories.length) {
      const isUserReadingHistory = this.chatScrollToBottom.current.scrollHeight - this.chatScrollToBottom.current.scrollTop !== this.chatScrollToBottom.current.clientHeight && this.chatScrollToBottom.current.scrollTop < 1000;
      if(isUserReadingHistory){
        console.log("Kullanıcı geçmiş mesajalrı okuyor")
      }
      if(!isUserReadingHistory){
        this.scrollToBottom();
      }
    }
  }

  scrollToBottom = () => {
    if (this.chatScrollToBottom.current) {
      const { scrollTop, scrollHeight, clientHeight } = this.chatScrollToBottom.current;
      if (scrollTop + clientHeight === scrollHeight) {
        return;
      }
      this.chatScrollToBottom.current.scrollTop = scrollHeight - clientHeight;
    } else {
      setTimeout(this.scrollToBottom, 100);
    }
  }

  getCreditRequestHistories() {
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
        if (errorMessages.length > 0) {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: errorMessages[0],
            buttons: 'Tamam'
          });
        }
      }
    }

    fetch(config.restApi + 'memberInfo', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({memberId: responseData.memberInfo.id});
        break;
        default:
          errorMessages.push(responseData.message);
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'creditRequestHistories/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({creditRequestHistories: responseData.creditRequestHistories});
        break;
        default:
          errorMessages.push(responseData.message);
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  getCreditDetail() {
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    fetch(config.restApi + 'creditRequest/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => responseData.status === 200 ? this.setState({isLoading: false, creditRequestInfo: responseData.creditRequestInfo}) : swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: responseData.message, buttons: 'Tamam'}).then(() => this.props.navigate('/')), () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Dene'}).then(() => this.getCreditDetail()));
  }

  saveCommentOnClick() {
    let infoMessage = '';

    if (!this.state.comment) {
      infoMessage = 'Mesaj boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({comment: this.state.comment})
      };

      fetch(config.restApi + 'creditRequestHistory/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => responseData.status === 200 ? this.setState({comment: ''}, () => {this.getCreditRequestHistories()}) : swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: responseData.message, buttons: 'Tamam'}).then(() => this.props.navigate('/')), () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  // handleScroll() {
  //   this.scrollToBottom();
  //   this.setState({isShowNewMessage: false});
  // }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="ibox">
          <div className="ibox-title text-black d-flex justify-content-between pr-2">
            <h4 className="text-black">Mesajlarım</h4>
            <div className="d-flex">
              <Link className="btn border bg-green text-white mr-2" to={'/creditRequestDetail?id=' + this.state.creditRequestInfo.id}><i className="fa fa-credit-card"></i> Başvuru Detayı Görüntüle</Link>
              <button className="btn border bg-darkblue text-white" onClick={() => this.props.navigate(-1)} title="Geri"><i className="fa fa-arrow-left"></i> Geri Dön</button>
            </div>
          </div>
          <div className="ibox-content">
            <div className="row">
              <div className="col">
                <h4 className="text-black">Kredi Başvuru Bilgileri</h4>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <div className="table-responsive-lg">
                  <table className="table table-bordered text-center">
                    <thead>
                      <tr className="text-black">
                        <th>İlan Başlığı</th>
                        <th>Başvuru Sahibi</th>
                        <th>Kredi Sağlayıcı</th>
                        <th>Telefon</th>
                        <th>Başvuru Tarihi</th>
                        <th>Kredi Durumu</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      <tr>
                        <td>{this.state.creditRequestInfo.advertTitle}</td>
                        <td>{this.state.creditRequestInfo.memberFullName}</td>
                        <td>{this.state.creditRequestInfo.providerName}</td>
                        <td>{<span>{this.state.creditRequestInfo.phoneNumber || '-'}</span>}</td>
                        <td>{isoToDateTime(this.state.creditRequestInfo.addedDate)}</td>
                        <td>{getCreditStatusName(this.state.creditRequestInfo.creditRequestStatus)}</td>
                      </tr>
                    }
                    {this.state.creditRequestInfo.length === 0 && <tr><td className="align-middle p-3 text-black" colSpan="6">Kredi Başvuru Bilgisi Bulunamadı !</td></tr>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="card">
                  <div className="card-body message-history" ref={this.chatScrollToBottom} style={{backgroundImage: 'url(' + Images.messageBackground + ')'}}>
                    {this.state.creditRequestHistories.map((creditHistoryInfo) => creditHistoryInfo.addedBy === this.state.memberId ?
                      <div className="d-flex flex-column" key={creditHistoryInfo.id}>
                        <div className="d-flex justify-content-end text-dark mr-1"><h6 className="mb-0">{isoToDate(creditHistoryInfo.addedDate)}</h6></div> 
                        <div className="d-flex justify-content-end">
                          <div className="pl-2 pt-1 mb-3 btn-sm bg-softgreen" style={{width: creditHistoryInfo.comment.length < 50 ? 'auto' : '50%', height: 'auto'}}>
                            {creditHistoryInfo.comment}
                            <div className="d-flex justify-content-end ml-5"><h6 className="mb-0">{isoToMysqlTime(creditHistoryInfo.addedDate)}</h6></div>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="d-flex flex-column mb-2" key={creditHistoryInfo.id}>
                        <div className="d-flex"><strong className="mr-2">{creditHistoryInfo.addedFullName}</strong><span className="d-flex align-items-center"><h6 className="mb-0">{isoToDate(creditHistoryInfo.addedDate)}</h6></span></div>
                        <div className="d-flex justify-content-start">
                          <div className="pl-2 pt-1 btn-sm bg-blue text-dar" style={{width: creditHistoryInfo.comment.length < 50 ? 'auto' : '50%', height: 'auto'}}>
                            {creditHistoryInfo.comment}
                            <div className="d-flex justify-content-end ml-5"><h6 className="mb-0">{isoToMysqlTime(creditHistoryInfo.addedDate)}</h6></div>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.creditRequestHistories.length === 0 && <div className="d-flex justify-content-center">Mesaj Geçmişi Bulunamadı !</div>}
                  </div>
                  {/* {
                    this.state.isShowNewMessage && <div className="d-flex justify-content-center mb-2"><button className="btn btn-primary btn-rounded" onClick={() => this.handleScroll()}>Yeni Mesajınız Var !</button></div>
                  } */}
                </div>
                <div className="mt-3">
                  <div className="input-group">
                    <input className="form-control form-input" onChange={(event) => this.setState({comment: event.target.value})} onKeyDown={(event) => event.key === 'Enter' && this.saveCommentOnClick()} placeholder="Mesaj giriniz.." value={this.state.comment} />
                    <div className="input-group-append">
                      <button className="input-group-text" onClick={this.saveCommentOnClick}><i className="fa fa-send-o text-black"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function CreditRequestHistories(props) {
  return (
    <CreditRequestHistoryModule navigate={useNavigate()} searchParams={useSearchParams()[0]}></CreditRequestHistoryModule>
  )
}
